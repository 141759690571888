import { Controller } from "@hotwired/stimulus";
import { useTransition } from "stimulus-use";
class src_default extends Controller {
  initialize() {
    this.hide = this.hide.bind(this);
  }
  connect() {
    useTransition(this);
    if (this.hiddenValue === false) {
      this.show();
    }
  }
  show() {
    this.enter();
    this.timeout = setTimeout(this.hide, this.delayValue);
  }
  async hide() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    await this.leave();
    this.element.remove();
  }
}
src_default.values = {
  delay: {
    type: Number,
    default: 3e3
  },
  hidden: {
    type: Boolean,
    default: false
  }
};
export { src_default as default };
